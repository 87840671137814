<template>
  <div>
    <b-card style="height: 75vh; overflow-y: auto" :title="lang('t_providers')">
      <b-card-text>
        <b-tabs
          vertical
          pills
          active-nav-item-class="bg-light-primary border-0 shadow-none cursor-pointer"
          lazy
        >
          <b-tab title-link-class="float-left " active>
            <template #title>
              <i class="bi bi-whatsapp font-medium-1"></i>

              <span>Whatsapp</span>
            </template>

            <wp></wp>
          </b-tab>
          <b-tab title-link-class="float-left ">
            <template #title>
              <i class="bi bi-chat-left-text font-medium-1"></i>

              <span>Text To Speech</span>
            </template>

            <tts></tts>
          </b-tab>
          <b-tab title-link-class="float-left ">
            <template #title>
              <i class="bi bi-reception-3 font-medium-1"></i>

              <span>Speech To Text</span>
            </template>

            <stt></stt>
          </b-tab>
          <b-tab title-link-class="float-left ">
            <template #title>
              <i class="bi bi-phone font-medium-1"></i>

              <span>SMS</span>
            </template>
            <sms-settings></sms-settings>
          </b-tab>
          <b-tab title-link-class="float-left ">
            <template #title>
              <i class="bi bi-envelope font-medium-1"></i>

              <span>{{ lang("t_email") }}</span>
            </template>
            <email-settings></email-settings>
          </b-tab>
          <b-tab title-link-class="float-left ">
            <template #title>
              <i class="bi bi-calendar2-check font-medium-1"></i>

              <span>{{ lang("t_timestamp") }}</span>
            </template>
            <timestamp></timestamp>
          </b-tab>
          <b-tab title-link-class="float-left ">
            <template #title>
              <i class="bi bi-hdd font-medium-1"></i>

              <span>{{ lang("t_ftp") }}</span>
            </template>
            <ftp></ftp>
          </b-tab>
          <b-tab title-link-class="float-left ">
            <template #title>
              <i class="bi bi-chat-dots font-medium-1"></i>

              <span>{{ lang("t_externalChat") }}</span>
            </template>
            <text-channel></text-channel>
          </b-tab>
          <b-tab title-link-class="float-left ">
            <template #title>
              <i class="bi bi-stars font-medium-1"></i>

              <span>{{ lang("t_artificialIntelligence") }}</span>
            </template>
            <ai></ai>
          </b-tab>
        </b-tabs>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BFormRadioGroup, BFormGroup, BRow, BCol, BCard, BCardText, BLink, BButton, BTabs, BTab } from 'bootstrap-vue'

import ftp from './FTP/list/List.vue'
import tts from './TTS/list/List.vue'
import stt from './STT/list/List.vue'
import wp from './Whatsapp/list/List.vue'
import ai from './AI/list/List.vue'
import SmsSettings from './SmsSettings/list/List.vue'
import EmailSettings from './EMailSettings/list/List.vue'
import Timestamp from './Timestamp/list/List.vue'
import TextChannel from './TextChannel/list/List.vue'

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
    }
  },
  components: {
    TextChannel,
    Timestamp,
    SmsSettings,
    EmailSettings,
    ai,
    ftp,
    stt,
    tts,
    wp,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
    BTabs, BTab
  },
}
</script>

<style>
</style>
